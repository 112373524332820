import styles from './footer.module.css';
import { version } from '../package.json';

export default function Footer () {
  return (
    <footer className={styles.footer}>
      <hr/>
      <ul className={styles.navItems}>
        <li className={styles.navItem}>contact <a href="mailto:hn@upscalews.com">hn@upscalews.com</a> for support / comments / questions / suggestions / etc. </li>
      </ul>
      <ul className={styles.navItems}>
        <li className={styles.navItem}>Created by <a href="https://will.vc">Will Harris</a></li>
        <li className={styles.navItem}><em>{version}</em></li>
      </ul>
    </footer>
  );
}