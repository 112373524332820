import Link from 'next/link';
// import { signOut, useSession } from 'next-auth/client';
import styles from './header.module.css';

// The approach used in this component shows how to built a sign in and sign out
// component that works on pages which support both client and server side
// rendering, and avoids any flash incorrect content on initial page load.
export default function Header () {
  // const [ session, loading ] = useSession();
  
  return (
    <header>
      <nav>
        <ul className={styles.navItems}>
          <li className={styles.navItem}><Link href="/"><a>Home</a></Link></li>
          <li className={styles.navItem}><Link href="/protected"><a> Profile </a></Link></li>
          <li className={styles.navItem}><Link href="/pricing"><a> Pricing </a></Link></li>
        </ul>
      </nav>
    </header>
  );
}